import Link from "next/link";
import {EyeTwoTone, MailTwoTone, PlaySquareTwoTone} from "@ant-design/icons";
import {Button, Checkbox, Form, Input, message, Row} from "antd";
import Router from "next/router";
import styled from "styled-components";
import {useState} from "react";
import {AuthenticationService, setToken} from "../services/AuthenticationService";

const FormItem = Form.Item;

const Content = styled.div`
  max-width: 400px;
  z-index: 2;
  min-width: 300px;
`;

export default function Login() {
  const [userName, setUserName] = useState('');
  const [password, setSetPassword] = useState('');
  const [error, setErrorMessage] = useState('');

  function login() {
    setErrorMessage('');
    const data = {
      username: userName,
      password: password,
    }
    AuthenticationService.login(data).then(
        (res) => {
          AuthenticationService.setToken(res.data.access_token, res.data.refresh_token, res.data.uid);
          Router.push('/').then(r => {});
        },
        (error) => {
          setErrorMessage('Wrong Username or Password');
          console.error(error);
        }
    );
  }

  return (<>
        <Row
            type="flex"
            align="middle"
            justify="center"
            className="px-3 bg-white mh-page"
            style={{minHeight: '100vh'}}>
          <Content>
            <div className="text-center mb-5">
              <Link href="/login">
                <a className="brand mr-0">
                  <PlaySquareTwoTone style={{fontSize: '32px'}}/>
                </a>
              </Link>
              <h5 className="mb-0 mt-3">Cariber</h5>
            </div>

            <Form layout="vertical"
                  onSubmit={e => {
                    e.preventDefault();
                    form.validateFields((err, values) => {
                      if (!err) {
                        message.success(
                            'Sign complete. Taking you to your dashboard!'
                        ).then(() => Router.push('/'));
                      }
                    });
                  }}>
              <FormItem label="Username" name="username"
                        rules={[{required: true, message: 'Please input your Username!'}]}>
                <Input prefix={<MailTwoTone style={{fontSize: '16px'}}/>}
                       type="username"
                       value={userName}
                       onChange={(e) => setUserName(e.target.value)}
                       placeholder="Username"/>
              </FormItem>

              <FormItem label="Password" name="password"
                        rules={[{required: true, message: 'Please input your Password!'}]}>
                <Input prefix={<EyeTwoTone style={{fontSize: '16px'}}/>}
                       type="password"
                       value={password}
                       onChange={(e) => setSetPassword(e.target.value)}
                       placeholder="Password"/>
              </FormItem>

              <FormItem name="remember" valuePropName="checked" initialValue={true}>
                <Button type="primary" htmlType="submit" block className="mt-3"
                        onClick={login}>
                  Log in
                </Button>
              </FormItem>

              <p className={'m-2 text-center ant-form-item-explain ant-form-item-explain-error'}>{error}</p>
            </Form>
          </Content>
        </Row>
      </>
  );
};
